import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import DashboardAdmin from "../views/DashboardAdmin.vue";
// import TableAlumni from "../views/TableAlumni.vue";
import TableAlumniAdmin from "../views/TableAlumniAdmin.vue";
import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import ProfileAlumni from "../views/ProfileAlumni.vue";
import SignIn from "../views/SignIn.vue";
import SignInAdmin from "../views/SignInAdmin.vue";
import SignUp from "../views/SignUp.vue";
//import SignUpAdmin from "../views/SignUpAdmin.vue";
import FormProfile from "../views/FormProfile.vue";
import FormProfileAdmin from "../views/FormProfileAdmin.vue";
import FormCreateAlumni from "../views/FormCreateAlumni.vue";
import FormTracer from "../views/FormTracer.vue";
import FormAkun from "../views/FormAkun.vue";
import FormAkunAdmin from "../views/FormAkunAdmin.vue";
import TableUser from "../views/TableUser.vue";
import TableFakultas from "../views/TableFakultas.vue";
import TableJurusan from "../views/TableJurusan.vue";
import cv from "../views/CurriculumVitae.vue";
import KartuAlumni from "../views/KartuAlumni.vue";
import LayoutFull from "@/views/layouts/Full.vue"
import LayoutApp from "@/views/layouts/App.vue"
/* import { useLoading } from 'vue3-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css"; */


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/",
    component: LayoutApp,
    children: [
      {
        path: "/dashboard/:id",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/dashboard",
        name: "Dashboard Admin",
        component: DashboardAdmin,
      },
      {
        path: "/admin/data_alumni",
        name: "Data Alumni",
        component: TableAlumniAdmin,
      },
      {
        path: "/admin/alumni/create",
        name: "Tambah Data Alumni",
        component: FormCreateAlumni,
      },
      {
        path: "/billing",
        name: "Billing",
        component: Billing,
      },
      {
        path: "/rtl-page",
        name: "RTL",
        component: RTL,
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "/profile/:id",
        name: "Profil",
        component: Profile,
      },
      {
        path: "/admin/profile_alumni/:id",
        name: "Profile Alumni",
        component: ProfileAlumni,
      },
      {
        path: "/admin/profile_alumni/:id/edit",
        name: "Admin Profile Alumni",
        component: FormProfileAdmin,
      },
      {
        path: "/form_profile/:id",
        name: "Form Profile",
        component: FormProfile,
      },
      {
        path: "/tracer_study/:id",
        name: "Tracer Study",
        component: FormTracer,
      },
      {
        path: "/admin/data_user",
        name: "Data User",
        component: TableUser,
      },
      {
        path: "/admin/data_fakultas",
        name: "Fakultas",
        component: TableFakultas,
      },
      {
        path: "/admin/data_jurusan",
        name: "Jurusan",
        component: TableJurusan,
      },
      {
        path: "/setting_account/:id",
        name: "Pengaturan Akun",
        component: FormAkun,
      },
      {
        path: "/admin/setting_account",
        name: "Pengaturan Akun Admin",
        component: FormAkunAdmin,
      },
      {
        path: "/cv/:id",
        name: "Biodata",
        component: cv,
      },
      {
        path: "/kartu_alumni/:id",
        name: "Kartu Alumni",
        component: KartuAlumni,
      },
    ]
  },
  {
    path: "/sign-in",
    component: LayoutFull,
    children: [
      {
        path: "/sign-in",
        name: "SignIn",
        component: SignIn,
      },
    ]
  },
  {
    path: "/admin/sign-in",
    component: LayoutFull,
    children: [
      {
        path: "/admin/sign-in",
        name: "SignIn Admin",
        component: SignInAdmin,
      },
    ]
  },
  {
    path: "/sign-up",
    component: LayoutFull,
    children: [
      {
        path: "/sign-up",
        name: "SignUp",
        component: SignUp,
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

/* router.beforeEach(() => {
  try {
    const loader = useLoading();
    loader.hide()
    loader.show({
      // Optional parameters
      container: null,
      canCancel: false,
      loader: 'bars',
      backgroundColor: '#fff',
      color: '#006CEE',
      opacity: 0.9,
      isFullPage: false
    });
    setTimeout(() => {
      loader.hide()
    },1000) 
  } catch (error) {
    console.log(error)
  }
}) */

export default router;
