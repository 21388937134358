<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 mb-md-0 mb-4">
        <form-card />
      </div>
    </div>
  </div>
</template>
<script>
import FormCard from "./components/FormCardAdmin.vue";

export default {
  name: "form-profile-admin",
  components: {
    FormCard,
  },
  methods: {
    addCustomer(customer) {
      this.customers.push(customer);
    },
  },
};
</script>
