<!-- prettier-ignore -->
<template>
  <div class="container-fluid py-4" style="min-height: 450px">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="
                bg-gradient-info
                shadow-light
                border-radius-lg
                pt-4
                pb-3
                px-3
              "
            >
              <div class="row">
                <div class="col-xs-12 col-md-3">
                  <h4
                    class="text-white text-capitalize d-inline"
                    style="margin-right: 10px"
                  >
                    Data Alumni
                  </h4>
                </div>

                <div class="col-xs-12 col-md-9">
                  <router-link
                    to="/admin/alumni/create"
                    class="btn btn-primary mb-1"
                    style="float: right"
                  >
                    <i class="fa fa-plus"></i> Tambah Alumni
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pb-1">
            <div>
              <button
                v-on:click="downloadTemplate()"
                target="__blank"
                class="btn btn-primary pull-right"
                style="margin-right: 10px"
              >
                <i class="fa fa-download"></i>
                 Download Template
              </button>
              <label
                class="btn btn-warning"
                for="bulk-import"
                style="margin-right: 10px"
                ><i class="fa fa-upload"></i> Import Massal</label
              >
              <input
                class="d-none"
                target=""
                style="margin-right: 10px"
                type="file"
                ref="files"
                id="bulk-import"
                @change="uploadFiles"
              />
              <button
                v-on:click="exportExcel()"
                target="__blank"
                class="btn btn-danger pull-right"
                style="margin-right: 10px"
              >
                <i class="fa fa-file-excel"></i>
                 Export Alumni
              </button>
              <button
                v-on:click="exportTracer()"
                target="__blank"
                class="btn btn-info pull-right"
                style="margin-right: 10px"
              >
                <i class="fa fa-file-excel"></i>
                 Export Tracer
              </button>
            </div>
            <div class="input-group input-group-outline">
              <select
                class="form-control"
                style="max-width: 120px; float: right; margin-right: 10px"
                v-model="filters.per_page"
              >
                <option disabled value="">Tampilkan Data</option>
                <option value="10">10 Data</option>
                <option value="20">20 Data</option>
                <option value="50">50 Data</option>
                <option value="100">100 Data</option>
                <option value="10000000">Semua Data</option>
              </select>
              <input
                type="text"
                class="form-control"
                placeholder="Cari nama/nik/nim"
                v-debounce:1500ms="search"
                style="max-width: 300px; float: right"
              />
            </div>
            <div class="table-responsive p-0" id="tbl_alumni">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Aktivasi
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Dokumen
                    </th>
                    <th
                      class="
                        text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Nama
                    </th>
                    <th
                      class="
                        text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                        ps-2
                      "
                    >
                      NIM
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      NIK
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Jenis Kelamin
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Tempat Lahir
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Tanggal Lahir
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Fakultas
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Jurusan
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      No Hp
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Alamat Tinggal
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Kecamatan
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Kabupaten
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Provinsi
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Negara
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Tahun Masuk
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Tahun Lulus
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Gelar Depan
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Gelar Belakang
                    </th>
                    <th
                      class="
                        text-center text-uppercase text-secondary text-xxs
                        font-weight-bolder
                        opacity-7
                      "
                    >
                      Act
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="alumni in alumnis" :key="alumni.id">
                    <td class="d-flex flex-column justify-content-center">
                      <strong
                        class="text-success p-1"
                        style="font-size: 12px"
                        v-if="alumni.completed == '1'"
                      >
                        <i class="fa fa-check"></i>
                        Data Lengkap
                      </strong>
                      <strong
                        class="text-warning p-1"
                        style="font-size: 12px"
                        v-else
                      >
                        <i class="fa fa-exclamation-triangle"></i>
                        Belum Lengkap
                      </strong>
                      <strong
                        class="text-success p-1"
                        style="font-size: 12px"
                        v-if="alumni.validated == '1'"
                      >
                        <i class="fa fa-check"></i>
                        Tervalidasi
                      </strong>
                      <strong
                        class="text-danger p-1"
                        style="font-size: 12px"
                        v-else-if="alumni.validated == '0'"
                      >
                        <i class="fa fa-times"></i>
                        Tidak Tervalidasi
                      </strong>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <a
                            v-if="alumni.bachelor_certificate_url != null"
                            :href="alumni.bachelor_certificate_url"
                            target="__blank"
                            class="badge badge-sm bg-gradient-info mb-1"
                            data-toggle="tooltip"
                            data-original-title=""
                          >
                            <i class="fa fa-check"></i>
                            Ijazah
                          </a>
                          <a
                            v-else
                            href="javascript:;"
                            class="badge badge-sm bg-gradient-danger mb-1"
                            data-toggle="tooltip"
                            data-original-title=""
                          >
                            <i class="fa fa-times"></i>
                            Ijazah
                          </a>
                          <a
                            v-if="alumni.identity_card_url != null"
                            :href="alumni.identity_card_url"
                            target="__blank"
                            class="badge badge-sm bg-gradient-info"
                            data-toggle="tooltip"
                            data-original-title=""
                          >
                            <i class="fa fa-check"></i>
                            KTP
                          </a>
                          <a
                            v-else
                            href="javascript:;"
                            class="badge badge-sm bg-gradient-danger mb-1"
                            data-toggle="tooltip"
                            data-original-title=""
                          >
                            <i class="fa fa-times"></i>
                            KTP
                          </a>
                          <!-- <a
                            v-if="profil.ijazah === 'ya'"
                            href="javascript:;"
                            class="badge badge-sm bg-gradient-primary mb-1"
                            data-toggle="tooltip"
                            data-original-title=""
                          >
                            <i class="fa fa-check"></i>
                            Ijazah
                          </a>
                          <a
                            v-if="profil.ktp === 'ya'"
                            href="javascript:;"
                            class="badge badge-sm bg-gradient-info"
                            data-toggle="tooltip"
                            data-original-title=""
                          >
                            <i class="fa fa-check"></i>
                            KTP
                          </a> -->
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div>
                          <img
                            v-if="alumni.photo_url != null"
                            :src="alumni.photo_url"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user"
                          />
                          <img
                            v-else
                            :src="ava"
                            class="avatar avatar-sm me-3 border-radius-lg"
                            alt="user"
                          />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            <router-link
                              :to="'/admin/profile_alumni/' + alumni.id"
                              >{{ alumni.name }}</router-link
                            >
                          </h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ alumni.email }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ alumni.nim }}
                      </p>
                    </td>
                    <td class="align-middle text-left text-sm">
                      <p class="text-xs font-weight-bold mb-0">
                        {{ alumni.nik }}
                      </p>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.gender }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.birth_place }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.birth_date }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.faculty_name }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.departement_name }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.phone_number }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.address }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.district }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.regency }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.province }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.country }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.entry_year }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                        {{ alumni.graduate_year }}
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">
                      </span>
                    </td>
                    <td class="align-middle">
                      <router-link
                        :to="'/admin/profile_alumni/' + alumni.id"
                        class="badge bg-gradient-primary"
                        data-toggle="tooltip"
                        data-original-title="Lihat Alumni"
                        style="margin-right: 5px"
                      >
                        <i class="fa fa-user"></i>
                      </router-link>
                      <router-link
                        :to="'/admin/profile_alumni/' + alumni.id + '/edit'"
                        class="badge bg-gradient-info"
                        data-toggle="tooltip"
                        data-original-title="Edit Alumni"
                        style="margin-right: 5px"
                      >
                        <i class="fa fa-pencil"></i>
                      </router-link>
                      <a
                        href="#"
                        @click="hapus(alumni)"
                        class="badge bg-gradient-danger"
                        data-toggle="tooltip"
                        data-original-title="Hapus Alumni"
                        style="margin-right: 5px"
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <vmd-pagination color="success">
                <template v-for="(link, index) in paginate.links" :key="link">
                  <vmd-pagination-item
                    v-if="index == 0"
                    :prev="true"
                    v-on:click="
                      filters.page > 1 ? toPage(filters.page - 1) : null
                    "
                  />
                  <vmd-pagination-item
                    v-else-if="index == paginate.links.length - 1"
                    :next="true"
                    v-on:click="
                      filters.page < paginate.last_page
                        ? toPage(filters.page + 1)
                        : null
                    "
                  />
                  <vmd-pagination-item
                    v-else
                    :label="link.label"
                    :active="link.active"
                    v-on:click="toPage(link.label)"
                  />
                </template>
              </vmd-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { vue3Debounce } from "vue-debounce";
import vmdPagination from "@/components/VmdPagination";
import vmdPaginationItem from "@/components/VmdPaginationItem";
import Select2 from "vue3-select2-component";
import VmdButton from "@/components/VmdButton.vue";
import axios from "@/libs/axios";
import ava from "@/assets/img/user.png";
// import $ from "jquery";
var XLSX = require("xlsx");

export default {
  name: "table-alumni",
  components: {
    Select2,
    VmdButton,
    vmdPagination,
    vmdPaginationItem,
  },
  directives: {
    debounce: vue3Debounce({ lock: true }),
  },
  data() {
    return {
      src: "",
      tahunLulus: "Pilih Tahun",
      fakultas: "Pilih Fakultas",
      jurusan: "Pilih Jurusan",
      optionsFakultas: [
        "Pilih Fakultas",
        "Teknik",
        "Bahasa dan Seni",
        "MIPA",
        "Ekonomi",
        "Ilmu Sosial",
      ],
      optionsJurusan: [
        "Pilih Jurusan",
        "Teknik Informatika",
        "Teknik Mesin",
        "Seni Rupa",
        "Ekonomi Bisnis",
        "Pendidikan Ilmu Sosial",
      ],
      optionsTahunLulus: [
        "Pilih Tahun",
        "2022",
        "2021",
        "2020",
        "2019",
        "2018",
      ], // or [{id: key, text: value}, {id: key, text: value}]
      alumnis: [],
      profil: "",
      ava,
      filters: {
        search: "",
        page: 1,
        per_page: 10,
      },
      paginate: {},
    };
  },
  methods: {
    search(val, e) {
      this.filters.search = val;
      this.load();
    },
    toPage(page) {
      this.filters.page = page;
      this.load();
    },
    downloadTemplate() {
      const link = document.createElement("a");
      link.href = this.template;
      link.download = "Template Bulk Import.xlsx";
      link.click();
    },
    exportExcel() {
      this.getExcelAlumni();
    },
    exportTracer() {
      this.getExcelTracer();
    },
    getExcelAlumni() {
      axios
        .get("/export_alumni", {
          responseType: "blob",
        })
        .then((res) => {
          var file = new File([res.data], "Template Bulk Import");
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download =
            "Data Alumni UNS " +
            this.$moment().format("HH-mm DD-MMMM-YYYY") +
            ".xlsx";
          link.click();
        })
        .catch((err) => {
          this.$swal({
            title: "Oops Maaf",
            text: "Terdapat Masalah Pada Jaringan!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    },
    getExcelTracer() {
      axios
        .get("/export_tracer", {
          responseType: "blob",
        })
        .then((res) => {
          var file = new File([res.data], "Template Bulk Import");
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download =
            "Data Tracer Study Alumni UNS " +
            this.$moment().format("HH-mm DD-MMMM-YYYY") +
            ".xlsx";
          link.click();
        })
        .catch((err) => {
          this.$swal({
            title: "Oops Maaf",
            text: "Terdapat Masalah Pada Jaringan!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    },
    getTemplate() {
      axios
        .get("/template", {
          responseType: "blob",
        })
        .then((res) => {
          var file = new File([res.data], "Template Bulk Import");
          this.template = window.URL.createObjectURL(file);
        })
        .catch((err) => {
          this.$swal({
            title: "Oops Maaf",
            text: "Terdapat Masalah Pada Jaringan!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        });
    },
    load() {
      const loader = this.$loading.show();
      axios
        .get("alumni", {
          params: this.filters,
        })
        .then((res) => {
          loader.hide();
          this.alumnis = res.data.user.data;
          this.paginate = res.data.user;
          this.filters.page = res.data.user.current_page;
        })
        .catch((err) => {
          loader.hide();
          console.log(err);
        });
    },
    show(alumni) {
      this.$router.push("/admin/profile_alumni/" + alumni.id);
    },
    hapus(alumni) {
      this.$swal({
        title: "Perhatian",
        text: "Yakin ingin menghapus " + alumni.name + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      }).then((button) => {
        if (button.isConfirmed) {
          axios
            .delete("alumni/" + alumni.id)
            .then((res) => {
              this.load();
              this.swalAlert(res.data.messege, "Sukses", "success");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    swalAlert(text, title, icon) {
      this.$swal({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    },
    previewFiles(e) {
      var input = e.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: "binary" });
        wb.SheetNames.forEach((sheetName) => {
          var rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          // this.excelData = JSON.stringify(rowObj)
          var obj = JSON.stringify(rowObj);
          var dt = JSON.parse(obj);
          for (var i = 0; i < dt.length; i++) {
            this.profil.push({
              name: dt[i].nama,
              email: dt[i].email,
              nik: dt[i].nik,
              nim: dt[i].nim,
              faculty: dt[i].fakultas,
              departement: dt[i].jurusan,
              entry_year: dt[i].tahun_masuk,
              graduate_year: dt[i].tahun_lulus,
              birth_date: dt[i].tanggal_lahir.split("-").reverse().join("-"),
              birth_place: dt[i].tempat_lahir,
              gender: dt[i].jenis_kelamin,
              address: dt[i].alamat_tinggal,
              phone_number: dt[i].no_hp,
              social_media: dt[i].sosial_media,
              gpa: dt[i].ipk,
              diploma_number: dt[i].no_ijazah,
              organization: dt[i].organisasi,
              achievement: dt[i].pencapaian,
            });
          }
          console.log(this.profil);
          const url = "alumni/";
          axios
            .post(url, this.profil)
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      };
      reader.readAsBinaryString(input.files[0]);
    },
    uploadFiles() {
      let self = this;
      self.profil = self.$refs.files.files[0];
      if (self.$refs.files.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          console.log(self.profil);
          // this.preview_ijazah = e.target.result;
        };
        reader.readAsDataURL(self.$refs.files.files[0]);
      }
      let formData = new FormData();
      formData.append("file", self.profil);
      const url = "import";
      const loader = this.$loading.show();
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          loader.hide();
          self
            .$swal({
              title: "Sukses",
              text: response.data.messege,
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            })
            .then(() => {
              self.load();
            });
        })
        .catch((error) => {
          loader.hide();
          this.swalAlert(error.response.data.messege, "Gagal", "error");
        });
    },
  },
  mounted() {
    this.load();
    this.getTemplate();
  },
  watch: {
    "filters.per_page": {
      handler(newValue, oldValue) {
        this.load();
      },
      deep: true,
    },
  },
};
</script>
