<!-- prettier-ignore -->
<template>
  <div class="card">
    <div class="card-body px-0 pb-2">
      <form v-on:submit.prevent="submit">
        <div class="row p-4">
          <div class="col-lg-2 col-sm-4 mb-3">
            <img :src="photo_url" style="width: 100%" />
          </div>
          <div class="col-lg-8 col-sm-6 mb-3">
            <h4>{{ profil.name }}</h4>
            <h5>{{ profil.email }}</h5>
            <!-- <ul>
              <li>Sudah Terverfikasi</li>
              <li>Data sudah Lengkap</li>
            </ul> -->
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="col-12"
                >Nama Lengkap <span class="text-danger">*</span></label
              ><br />
              <input
                type="text"
                placeholder="Masukkan nama lengkap"
                class="form-control"
                v-model="profil.name"
                name="name"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >No Ijazah <span class="text-danger">*</span></label
              ><br />
              <input
                type="text"
                placeholder="Masukkan nomor ijazah"
                class="form-control"
                v-model="profil.diploma_number"
                name="diploma_number"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >IPK <span class="text-danger">*</span></label
              ><br />
              <input
                type="number"
                step="0.01"
                min="0"
                lang="id"
                placeholder="Masukkan IPK, contoh: 3.5, 3.9, 4.0"
                class="form-control"
                v-model="profil.gpa"
                name="gpa"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >NIM <span class="text-danger">*</span></label
              ><br />
              <input
                type="text"
                placeholder="Masukkan NIM"
                class="form-control"
                v-model="profil.nim"
                name="nim"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >NIK <span class="text-danger">*</span></label
              ><br />
              <input
                type="number"
                placeholder="Masukkan NIK"
                class="form-control"
                v-model="profil.nik"
                name="nik"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Tempat Lahir <span class="text-danger">*</span></label
              ><br />
              <input
                type="text"
                placeholder="Masukkan tempat lahir"
                class="form-control"
                v-model="profil.birth_place"
                name="birth_place"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Tanggal Lahir <span class="text-danger">*</span></label
              ><br />
              <input
                type="date"
                placeholder="Masukkan tanggal lahir"
                class="form-control"
                v-model="profil.birth_date"
                name="birth_date"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Tahun Masuk <span class="text-danger">*</span></label
              ><br />
              <input
                type="number"
                placeholder="Masukkan tahun masuk"
                min="1900"
                max="2099"
                step="1"
                class="form-control"
                v-model="profil.entry_year"
                name="entry_year"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Tahun Lulus <span class="text-danger">*</span></label
              ><br />
              <input
                type="number"
                placeholder="Masukkan tahun lulus"
                min="1900"
                max="2099"
                step="1"
                class="form-control"
                v-model="profil.graduate_year"
                name="graduate_year"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Fakultas <span class="text-danger">*</span></label
              ><br />
              <div class="col-12">
                <Select2
                  v-model="profil.faculty_id"
                  :options="myOptions"
                  :settings="{ placeholder: 'Pilih Fakultas' }"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Jurusan <span class="text-danger">*</span></label
              ><br />
              <div class="col-12">
                <Select2
                  v-model="profil.departement_id"
                  :options="myOptions2"
                  :settings="{ placeholder: 'Pilih Jurusan' }"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >No.Hp <span class="text-danger">*</span></label
              ><br />
              <input
                type="text"
                placeholder="Masukkan nomor HP"
                class="form-control"
                v-model="profil.phone_number"
                name="phone_number"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Email <span class="text-danger">*</span></label
              ><br />
              <input
                type="text"
                placeholder="Masukkan alamat email"
                class="form-control"
                v-model="profil.email"
                name="email"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Jenis Kelamin <span class="text-danger">*</span></label
              ><br />
              <div class="col-12">
                <select
                  name="gender"
                  id="gender"
                  class="form-control"
                  v-model="profil.gender"
                >
                  <option disabled selected value="">
                    Pilih Jenis Kelamin
                  </option>
                  <option value="Laki-laki">Laki-laki</option>
                  <option value="Perempuan">Perempuan</option>
                </select>
                <!-- <Select2 v-model="profil.Provinsi" :options="myOptions3" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Negara <span class="text-danger">*</span></label
              ><br />
              <div class="col-12">
                <Select2
                  ref="country"
                  v-model="profil.country"
                  :options="countries"
                  :settings="{ placeholder: 'Pilih Negara Domisili' }"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Provinsi <span class="text-danger">*</span></label
              ><br />
              <div class="col-12" v-if="profil.country == 'Indonesia'">
                <Select2
                  id="province"
                  ref="province"
                  v-model="profil.province"
                  :options="provinces"
                  :settings="{ placeholder: 'Pilih Provinsi Domisili' }"
                />
              </div>
              <input
                v-else
                placeholder="Masukkan Provinsi domisili"
                type="text"
                class="form-control"
                v-model="profil.province"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Kabupaten <span class="text-danger">*</span></label
              ><br />
              <div class="col-12" v-if="profil.country == 'Indonesia'">
                <Select2
                  v-model="profil.regency"
                  :options="regencies"
                  :settings="{ placeholder: 'Pilih Kota/Kabupaten Domisili' }"
                />
              </div>
              <input
                v-else
                placeholder="Masukkan Kabupaten domisili"
                type="text"
                class="form-control"
                v-model="profil.regency"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Kecamatan <span class="text-danger">*</span></label
              ><br />
              <div class="col-12" v-if="profil.country == 'Indonesia'">
                <Select2
                  v-model="profil.district"
                  :options="districts"
                  :settings="{ placeholder: 'Pilih Kecamatan Domisili' }"
                />
              </div>
              <input
                v-else
                placeholder="Masukkan Kecamatan domisili"
                type="text"
                class="form-control"
                v-model="profil.district"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12"
                >Alamat <span class="text-danger">*</span></label
              ><br />
              <input
                placeholder="Masukkan Alamat domisili (desa/jalan)"
                type="text"
                class="form-control"
                v-model="profil.address"
                name="address"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Sosial Media (opsional)</label><br />
              <input
                type="text"
                placeholder="Masukkan sosial media"
                class="form-control"
                v-model="profil.social_media"
                name="social_media"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Organisasi (opsional)</label><br />
              <input
                type="text"
                placeholder="Masukkan organisasi"
                class="form-control"
                v-model="profil.organization"
                name="organization"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="input-group input-group-outline mb-3">
              <label class="label col-12">Pencapaian (opsional)</label><br />
              <input
                type="text"
                placeholder="Masukkan pencapaian"
                class="form-control"
                v-model="profil.achievement"
                name="achievement"
                isrequired="true"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <div class="input-group input-group-outline mb-3">
                  <label class="label col-12"
                    >Foto <span class="text-danger">*</span></label
                  ><br />
                  <input
                    type="file"
                    class="form-control col-12"
                    name="foto"
                    ref="file"
                    id="file"
                    isrequired="true"
                    @change="uploadPhoto()"
                    accept=".png, .jpeg, .jpeg"
                  /><br />
                  <small id="emailHelp" class="form-text text-muted"
                    ><i
                      >File harus berupa foto dengan format png/jpg/jpeg dan
                      berukuran maks 2MB.</i
                    ></small
                  >
                </div>
                <div class="border p-2 mt-3">
                  <p>Preview:</p>
                  <template v-if="preview">
                    <img
                      :src="preview"
                      class="img-fluid"
                      style="width: 150px"
                    />
                  </template>
                </div>
                <!-- <img :src="photo_url" class="shadow-sm border-radius-sm" style="width:80px; margin-bottom:10px"> -->
              </div>
              <div class="col-lg-4 col-sm-12">
                <div class="input-group input-group-outline mb-3">
                  <label class="label col-12"
                    >KTP <span class="text-danger">*</span></label
                  ><br />
                  <input
                    type="file"
                    class="form-control col-12"
                    name="ktp"
                    ref="ktp"
                    id="ktp"
                    isrequired="true"
                    @change="uploadKTP()"
                    accept=".png, .jpeg, .jpeg"
                  /><br />
                  <small id="emailHelp" class="form-text text-muted"
                    ><i
                      >File harus berupa foto dengan format png/jpg/jpeg dan
                      berukuran maks 2MB.</i
                    ></small
                  >
                </div>
                <div class="border p-2 mt-3">
                  <p>Preview:</p>
                  <template v-if="preview_ktp">
                    <img
                      :src="preview_ktp"
                      class="img-fluid"
                      style="width: 150px"
                    />
                  </template>
                </div>
                <!-- <img :src="ktp_url" class="shadow-sm border-radius-sm" style="width:80px; margin-bottom:10px"> -->
              </div>
              <div class="col-lg-4 col-sm-12">
                <div class="input-group input-group-outline mb-3">
                  <label class="label col-12"
                    >Ijazah <span class="text-danger">*</span></label
                  ><br />
                  <input
                    type="file"
                    class="form-control col-12"
                    name="ijazah"
                    ref="ijazah"
                    id="ijazah"
                    isrequired="true"
                    @change="uploadIjazah()"
                    accept=".png, .jpeg, .jpeg"
                  /><br />
                  <small id="emailHelp" class="form-text text-muted"
                    ><i
                      >File harus berupa foto dengan format png/jpg/jpeg dan
                      berukuran maks 2MB.</i
                    ></small
                  >
                </div>
                <div class="border p-2 mt-3">
                  <p>Preview:</p>
                  <template v-if="preview_ijazah">
                    <img
                      :src="preview_ijazah"
                      class="img-fluid"
                      style="width: 150px"
                    />
                  </template>
                </div>
                <!-- <img :src="ijazah_url" class="shadow-sm border-radius-sm" style="width:80px; margin-bottom:10px"> -->
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6">
            <vmd-button
              class="my-4 mb-2"
              variant="gradient"
              color="info"
              fullWidth
              >Simpan
            </vmd-button>
          </div>
          <div class="col-lg-2 col-sm-6">
            <vmd-button
              class="my-4 mb-2"
              variant="gradient"
              color="light"
              type="button"
              fullWidth
              >Batal
            </vmd-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Select2 from "vue3-select2-component";
import VmdButton from "@/components/VmdButton.vue";
import axios from "@/libs/axios";
let self;
export default {
  name: "form-card",
  components: {
    Select2,
    VmdButton,
  },
  created: function () {
    self = this;
    this.load();
    this.load_faculty();
  },
  data() {
    return {
      src: "",
      photo_url: "",
      ktp_url: "",
      ijazah_url: "",
      preview: null,
      preview_ktp: null,
      preview_ijazah: null,
      token: localStorage.getItem("token"),
      myOptions: [],
      myOptions2: [],
      countries: [],
      provinces: [],
      regencies: [],
      districts: [],
      villages: [],
      // myOptions3: ['Pilih Provinsi','Aceh', 'Bandung', 'Jakarta', 'DI Yogyakarta', 'Jawa Tengah'], // or [{id: key, text: value}, {id: key, text: value}]
      //optionFakultas: [],
      profil: {
        name: "",
        diploma_number: "",
        gpa: "",
        nim: "",
        nik: "",
        birth_place: "",
        birth_date: "",
        entry_year: "",
        graduate_year: "",
        phone_number: "",
        country: "",
        province: "",
        regency: "",
        district: "",
        address: "",
        email: "",
        faculty_id: "",
        departement_id: "",
        gender: "",
        social_media: "",
        organization: "",
        achievement: "",
        photo: "",
        identity_card: "",
        bachelor_certificate: "",
      },
      showSelectAddress: false
    };
  },
  watch: {
    "profil.faculty_id": {
      handler(id) {
        this.load_departement(id);
      },
      // force eager callback execution
      immediate: true,
    },
    "profil.country": {
      handler(id) {
        this.load_provinces(id);
      },
      // force eager callback execution
      immediate: true,
    },
    "profil.province": {
      handler(id) {
        let data = this.provinces.find(element => element.text === id);
        if(data && data.province_id) {
          this.load_regencies(data.province_id);
        }
      },
      // force eager callback execution
      immediate: true,
    },
    "profil.regency": {
      handler(id) {
        let data = this.regencies.find(element => element.text === id);
        if(data && data.regency_id) {
          this.load_districts(data.regency_id);
        }
      },
      // force eager callback execution
      immediate: true,
    }
  },
  methods: {
    load() {
      axios
        .get("/profile/" + self.$route.params.id)
        .then((res) => {
          this.load_countries();
          self.profil.name = res.data.user.name;
          self.profil.nim = res.data.user.nim;
          self.profil.nik = res.data.user.nik;
          self.profil.birth_place = res.data.user.birth_place;
          self.profil.birth_date = res.data.user.birth_date;
          self.profil.entry_year = res.data.user.entry_year;
          self.profil.graduate_year = res.data.user.graduate_year;
          self.profil.phone_number = res.data.user.phone_number;
          self.profil.country = res.data.user.country;
          self.profil.province = res.data.user.province;
          self.profil.regency = res.data.user.regency;
          self.profil.district = res.data.user.district;
          self.profil.address = res.data.user.address;
          self.profil.email = res.data.user.email;
          self.profil.diploma_number = res.data.user.diploma_number;
          self.profil.gpa = res.data.user.gpa;
          self.profil.social_media = res.data.user.social_media;
          self.profil.organization = res.data.user.organization;
          self.profil.achievement = res.data.user.achievement;
          self.photo_url = res.data.user.photo_url;
          self.ktp_url = res.data.user.identity_card_url;
          self.ijazah_url = res.data.user.bachelor_certificate_url;
          if (
            res.data.user.faculty_id == null ||
            res.data.user.faculty_id == ""
          ) {
            self.profil.faculty_id = "Pilih Fakultas";
          } else {
            self.profil.faculty_id = res.data.user.faculty_id;
          }
          if (
            res.data.user.departement_id == null ||
            res.data.user.departement_id == ""
          ) {
            self.profil.departement_id = "Pilih Jurusan";
          } else {
            self.profil.departement_id = res.data.user.departement_id;
          }
          if (res.data.user.gender == null || res.data.user.gender == "") {
            self.profil.gender = "";
          } else {
            self.profil.gender = res.data.user.gender;
          }
          if (
            res.data.user.photo_url != null ||
            res.data.user.photo_url != ""
          ) {
            this.preview = res.data.user.photo_url;
          }
          if (
            res.data.user.identity_card_url != null ||
            res.data.user.identity_card_url != ""
          ) {
            this.preview_ktp = res.data.user.identity_card_url;
          }
          if (
            res.data.user.bachelor_certificate_url != null ||
            res.data.user.bachelor_certificate_url != ""
          ) {
            this.preview_ijazah = res.data.user.bachelor_certificate_url;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load_faculty() {
      axios
        .get("list_faculty")
        .then((res) => {
          for (var i = 0; i < res.data.faculty.length; i++) {
            this.myOptions.push({
              id: res.data.faculty[i].id,
              text: res.data.faculty[i].faculty_name,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    load_departement(id) {
      if (id) {
        axios
          .get("list_departement/" + id)
          .then((res) => {
            this.myOptions2 = [];
            for (var i = 0; i < res.data.Departement.length; i++) {
              this.myOptions2.push({
                id: res.data.Departement[i].id,
                text: res.data.Departement[i].departement_name,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    load_countries() {
        axios
          .get("country")
          .then((res) => {
            this.countries = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.countries.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    load_provinces(data) {
      if (data == 'Indonesia') {
        axios
          .get("province")
          .then((res) => {
            this.provinces = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.provinces.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name,
                province_id: res.data.data[i].id,
              });
            }
            let data = this.provinces.find(element => element.text === this.profil.province);
            if(data && data.province_id) {
              this.load_regencies(data.province_id);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    load_regencies: function(province_id) {
        axios
          .get("regency/"+province_id)
          .then((res) => {
            this.regencies = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.regencies.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name,
                regency_id: res.data.data[i].id,
              });
            }
            let data = this.regencies.find(element => element.text === this.profil.regency);
            if(data && data.regency_id) {
              this.load_districts(data.regency_id);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    load_districts: function(regency_id) {
        axios
          .get("district/"+regency_id)
          .then((res) => {
            this.districts = [];
            for (var i = 0; i < res.data.data.length; i++) {
              this.districts.push({
                id: res.data.data[i].name,
                text: res.data.data[i].name,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    uploadPhoto() {
      self.profil.photo = self.$refs.file.files[0];
      console.log(self.profil.photo);
      if (self.$refs.file.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        reader.readAsDataURL(self.$refs.file.files[0]);
      }
    },
    uploadKTP() {
      self.profil.identity_card = self.$refs.ktp.files[0];
      console.log(self.profil.identity_card);
      if (self.$refs.ktp.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview_ktp = e.target.result;
        };
        reader.readAsDataURL(self.$refs.ktp.files[0]);
      }
    },
    uploadIjazah() {
      self.profil.bachelor_certificate = self.$refs.ijazah.files[0];
      console.log(self.profil.bachelor_certificate);
      if (self.$refs.ijazah.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview_ijazah = e.target.result;
        };
        reader.readAsDataURL(self.$refs.ijazah.files[0]);
      }
    },
    submit() {
      let formData = new FormData();
      for (const key in self.profil) {
        if (
          (self.profil[key] &&
            self.profil[key] != null &&
            self.profil[key] &&
            "") ||
          (self.profil[key] && "null")
        ) {
          formData.append(key, self.profil[key]);
        }
      }
      formData.append("_method", "POST");
      const url = "profile/" + self.$route.params.id;
      const loader = this.$loading.show();
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          loader.hide();
          self
            .$swal({
              title: "Sukses",
              text: response.data.messege,
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            })
            .then((result) => {
              self.load();
            });
        })
        .catch((error) => {
          loader.hide();
          var obj = JSON.stringify(error.response.data);
          var dt = JSON.parse(obj);
          this.swalAlert(dt.message, "Gagal", "error");
        });
    },
    swalAlert(text, title, icon) {
      this.$swal({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    },
  },
};
</script>
<style>
.select2 {
  width: 100% !important;
}
</style>